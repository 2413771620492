<template>
 <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Bills And Receipts Report Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Sales Bills
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Sales Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-sm-0 d-flex justify-content-end col-lg-7 p-1">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-12 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="ml-2" v-model="filter.session_year">
            <option v-for="(year,index) in sessionYears" :key="index" :value="year">{{year}}/{{parseInt(year.substring(year.length-2)) + 1}}</option>
          </select>
          <select v-model="filter.branch" class="filter-input ml-2">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in dataLists2"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevDay">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="toDay"> Today </a></li>
            <li>
              <a href="javascript:;" @click="nextDay">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevMonth">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="thisMonth"> Month </a></li>
            <li>
              <a href="javascript:;" @click="nextMonth">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input
              type="date"
              v-model="filter.from"
              placeholder="From"
              class="filter-input"
            />
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input
              type="date"
              v-model="filter.to"
              placeholder="To"
              class="ml-1 filter-input"
            />
          </div>
          <div class="ml-2">
            <input
                type="text"
                v-model="filter.key"
                class="filter-input"
                placeholder="Search..."
            />
          </div>
          <div class="float-right">
            <select class="filter-input ml-2 float-right" v-model="route" @change="filterReportType">
              <option value="/general-report/bills">Sales Bills</option>
              <option value="/general-report/purchasesBills">Purchase Bills</option>
              <option value="/general-report/payInReceipts">Pay In receipt</option>
              <option value="/general-report/payOutReceipts">Pay Out receipt</option>
              <option value="/general-report/salarySlips">Salary Slip</option>
            </select>
          </div>
        </div>
      </div>
    </div>
   
    <div class="row py-0">
      <div class="col-md-9 mx-auto" v-for="(details, index) in dataSets" :key="index">
   
         
            <div class="row">
             
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download"></i>
                </div>
                <div class="bill-head-icon mr-1" @click="$store.dispatch('modalClose')">
                  <i class="fas fa-times"></i>
                </div>
              </div>
           
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxl mb-0">Softechpark Pvt. Ltd.</p>
              <p class="mb-0">
                201 Something St., Something Town, YT 242, Country
                <br />Tel No: 324 445-4544 / youremail@companyname.com
                <br />PAN: 123456789
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Billed To</label>
                <p class="mg-b-0">NAME : {{details.customer.name}}</p>
                <p class="mg-b-0">ADDRESS : {{details.customer.address}}</p>
                <p class="mg-b-0">PAN : {{details.customer.pan_vat_no}}</p>
              </div>
              <div class="col-sm-6 col-lg-4 mg-t-30">
                <p class="mg-b-0 text-right">Bill No : #PRCS{{threeDigit(details.id)}}</p>
                <p class="mg-b-0 text-right">DATE : {{details.created_date}}</p>
              </div>
            </div>
            <div class="mt-2">
              <table class="table table3">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N</th>
                    <th scope class="wd-60p">Particular</th>
                    <th scope class="wd-15p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-10p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                   <tr v-for="(item,index) in details.items" :key="index">
                    <th scope="row">{{++index}}</th>
                    <td>{{item.item.name}}</td>
                    <td>{{item.quantity}} KG</td>
                    <td>{{item.sales_rate}}</td>
                    <td class="tx-right">{{item.total}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div class="col-sm-7 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                <div class="footer_detail mt-0 mb-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p class="mg-b-0">{{toWord(details.bill_amount)}}</p>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 table_lr_border color-secondary">
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Payment ID</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                     <tbody>
                      <tr>
                        <td>{{details.payment_methods.title}}</td>
                        <td>Front-End</td>
                        <td>{{details.paid_amount}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Shipping-detail -->
                <div class="footer_detail mt-2 mb-0 p-2">
                  <label class="content-label mb-1">Shipping Detail</label>
                  <p class="mg-b-0">Vehicle No : {{details.vehicle}}</p>
                  <p class="mg-b-0">Driver Name : {{details.driver}}</p>
                  <p class="mg-b-0">Contact No : {{details.contact}}</p>
                </div>
                <!-- Shipping-detail -->
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0">
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Sub-Total</span>
                    <span>{{details.total}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Bill discount</span>
                    <span>{{details.bill_discount}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT able Amount</span>
                    <span>{{details.vatable_amount}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT (13%)</span>
                    <span>{{details.vat_amount}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total</span>
                    <span>{{details.bill_amount}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total Paid</span>
                    <span>{{details.paid_amount}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total Due</span>
                    <span>{{details.due_amount}}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />
            <div class="footer_note" v-if="details.note">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">{{details.note}}</p>
            </div>
            <p class="text-right">User: {{details.user_details.name}}</p>
          </div>
       
   
  </div>
    </div>
  </div>
  
</template>
<script>
import { Search } from "../../../../../mixins/search";
import { mapGetters } from "vuex";
export default {
  mixins: [Search],
  data(){
    return{
        sessionYears:['2018','2019','2020','2021'],
      route:this.$route.path
    }
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "dataLists2",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
  },
  methods: {
   
  filterByBranch() {
      if (this.dataLists.length != 0) {
        let branch = this.filter.branch;
        let details = this.dataLists.filter(function (data) {
          return data.branch == branch;
        });
        this.dataSets = { ...details };
      }
    },
    filterReportType() {
      if(this.$route.path!=this.route) {
        this.$router.push(this.route);
      }
    },
    filterByTwoDates() {
      this.$store.commit("setApiUrl", "api/billsReciepts/saleBills/filter/date");
      this.$store.commit("sendData", this.filter);
    },
    filterByDay() {
      this.$store.commit("setApiUrl","api/billsReciepts/saleBills/filter/day");
      this.$store.commit("sendData", {
        size: this.filter.size,
        day: this.filter.full_day,
      });
    },
    filterByMonth() {
      this.$store.commit("setApiUrl", "api/billsReciepts/saleBills/filter/month");
      this.$store.commit("sendData", {
        size: this.filter.size,
        month: this.filter.full_month,
      });
    },
    getData() {
      this.$store.commit("getData", `api/billsReciepts/saleBills/size/${this.filter.size}/year/${this.filter.session_year}`);
    },
  },
  beforeMount() {
    this.$store.commit("getData2", `api/branch`);
    this.getData();
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>